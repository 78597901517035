<template>
  <div class="login-layout">
    <div class="logo">
      <van-image
        class="logoimg"
        width="30vw"
        height="30vw"
        round
        fit="contain"
        lazy-load
        :src="require('@/assets/images/logo.png')"
      />
    </div>
    <van-form class="login-form" @submit="onSubmit">
      <van-cell-group>
        <van-field
          v-model="curPerfix"
          is-link
          readonly
          left-icon="aog iconfont icon-ditu"
          @click="showPrefix = true"
        />
        <van-popup v-model:show="showPrefix" round position="bottom">
          <van-picker
            :columns="mobileperfix"
            @cancel="showPrefix = false"
            @confirm="onConfirm"
            v-model="selectedlang"
            :title="PAGELANG.area"
          />
        </van-popup>

        <van-field
          v-model="invitecode"
          :placeholder="PAGELANG.invitecode"
          left-icon="aog iconfont icon-yaoqingma-02"
        />
        <van-field
          v-model="username"
          :placeholder="PAGELANG.username"
          left-icon="aog iconfont icon-mobile"
          :rules="rules.username"
        />
        <van-field
          v-model="password"
          type="password"
          :placeholder="PAGELANG.password"
          left-icon="aog iconfont icon-password"
          :rules="rules.password"
        />
        <van-field
          v-model="password2"
          type="password"
          :placeholder="PAGELANG.password2"
          left-icon="aog iconfont icon-password"
          :rules="rules.password2"
        />
        <van-field
          v-model="agentname"
          type="text"
          :placeholder="PAGELANG.agentname"
          left-icon="aog iconfont icon-my"
          :rules="rules.agentrequired"
        />
        <van-field
          v-model="email"
          type="text"
          :placeholder="PAGELANG.email"
          left-icon="aog iconfont icon-email"
        />
      </van-cell-group>

      <van-button
        v-if="isloading"
        color="var(--van-card-price-color)"
        block
        loading
        type="primary"
        :loading-text="PAGELANG.in_login"
        size="large"
        class="btn-login"
      />
      <van-button
        v-else
        color="var(--van-card-price-color)"
        block
        type="primary"
        native-type="submit"
        size="large"
        class="btn-login"
      >
        {{ PAGELANG.login_btn }}
      </van-button>
    </van-form>
  </div>

  <div class="login-layout-pc">
    <PCHeader />
    <div class="pc-banner">
      <div class="login-block">
        <div class="big-title">{{ PAGELANG.login_btn }}</div>
        <el-form
          :rules="rules"
          :model="reginfo"
          class="form-login"
          ref="refLogin"
        >
          <el-form-item>
            <i class="iconfont icon-ditu" />
            <el-select
              class="mobile-perfix"
              v-model="langcode"
              :placeholder="PAGELANG.请选择语言"
              @change="onChangeLang"
            >
              <template v-for="(item, index) in mobileperfix" :key="index">
                <el-option :label="item.text" :value="item.value" />
              </template>
            </el-select>
          </el-form-item>

          <el-form-item prop="invitecode" :inline-message="true">
            <i class="iconfont icon-yaoqingma-02" />
            <el-input
              v-model="reginfo.invitecode"
              :placeholder="PAGELANG.invitecode"
            />
          </el-form-item>

          <el-form-item prop="username" :inline-message="true">
            <i class="iconfont icon-mobile" />
            <el-input
              v-model="reginfo.username"
              :placeholder="PAGELANG.username"
              autocomplete="off"
            />
          </el-form-item>

          <el-form-item prop="password" :inline-message="true">
            <i class="iconfont icon-password" />
            <el-input
              v-model="reginfo.password"
              type="password"
              autocomplete="off"
              :placeholder="PAGELANG.password"
            />
          </el-form-item>

          <el-form-item prop="passwrod2">
            <i class="iconfont icon-password" />
            <el-input
              v-model="reginfo.password2"
              type="password"
              autocomplete="off"
              :placeholder="PAGELANG.password2"
            />
          </el-form-item>

          <el-form-item prop="agentrequired" :inline-message="true">
            <i class="iconfont icon-my" />
            <el-input
              v-model="reginfo.agentrequired"
              :placeholder="PAGELANG.agentrequired"
            />
          </el-form-item>

          <el-form-item prop="email" :inline-message="true">
            <i class="iconfont icon-email" />
            <el-input v-model="reginfo.email" :placeholder="PAGELANG.email" />
          </el-form-item>

          <el-form-item style="border-bottom: 0; height: auto">
            <el-button
              v-if="isloading"
              class="btn-login"
              type="primary"
              color="#000000"
              loading
              >{{ PAGELANG.in_login }}</el-button
            >
            <el-button
              v-else
              class="btn-login"
              type="primary"
              color="#000000"
              :auto-insert-space="true"
              @click="onRegister"
              >{{ PAGELANG.login_btn }}</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
    <PCFooter />
  </div>
</template>
<style>
.van-icon-aog {
  color: #cfaf6d;
}
</style>
<style scoped>
@media (max-width: 768px) {
  .login-layout-pc {
    display: none;
  }
  .login-layout {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }

  .login-layout .logo {
    position: relative;
    border-radius: 50%;
  }

  .login-layout .logo::before,
  .login-layout .logo::after {
    content: "";
    display: block;
    position: absolute;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    border-radius: inherit;
  }

  .login-layout .logo::before {
    top: 1px;
    left: 1px;
    z-index: 10;
    background-color: #fff;
  }

  .login-layout .logo::after {
    top: 0;
    left: 0;
    z-index: 1;
    padding: 1px;
    background: linear-gradient(to right, #fff, #d5ba80);
  }

  .login-layout .logo .logoimg {
    z-index: 100;
  }

  .login-form {
    width: 90vw;
    margin-top: 9vw;
    --van-cell-horizontal-padding: 0;
    --van-padding-md: 0;
  }

  .van-icon-aog {
    color: #cfaf6d;
  }

  .forget {
    line-height: 38px;
    font-size: 12px;
    text-align: right;
  }

  .forget > a {
    color: #143949;
  }

  .btn-login {
    margin-top: 40px;
  }

  .register {
    text-align: center;
    padding-top: 20px;
    line-height: 44px;
    font-size: 12px;
    color: #979797;
  }

  .register > a {
    font-size: 14px;
    color: #143949;
    margin-left: 10px;
  }
}
</style>
<style src="../assets/css/login.css" scoped></style>

<script>
import { ref, getCurrentInstance } from "vue";
import { closeToast, showNotify, showLoadingToast } from "vant";
import { getCookie, setCookie } from "../util/index.js";

import PCHeader from "../components/PCHeader.vue";
import PCFooter from "../components/PCFooter.vue";

export default {
  components: {
    PCHeader,
    PCFooter,
  },
  methods: {
    onConfirm({ selectedOptions }) {
      this.showPrefix = false;
      this.curPerfix = selectedOptions[0].text;
      setCookie("lh_aog_langcode", selectedOptions[0].value);
      // this.langcode = selectedOptions[0].value;
      //getCurrentInstance().config.globalProperties.langcode = selectedOptions[0].value;

      //this.appContext.config.globalProperties.langcode = selectedOptions[0].value;

      location.reload();
    },

    onChangeLang(val) {
      this.langcode = val;
      setCookie("lh_aog_langcode", val);
      location.reload();
    },

    onRegister() {
      this.$refs.refLogin.validate((valid) => {
        if (valid) {
          this.invitecode = this.reginfo.invitecode;
          this.agentname = this.reginfo.agentrequired;
          this.password = this.reginfo.password;
          this.password2 = this.reginfo.password2;
          this.username = this.reginfo.username;
          this.email = this.reginfo.email ? this.reginfo.email : "";

          this.onSubmit();
        } else {
          return false;
        }
      });
    },

    onSubmit() {
      console.log("submit");
      console.log(this.username, this.password, this.password2, this.agentname);

      this.isloading = true;

      showLoadingToast({
        message: this.SYSLANG.loading,
        duration: 0,
        forbidClick: true,
      });

      if (this.password != this.password2) {
        // showFailToast(this.PAGELANG.inconformity);
        closeToast();
        this.isloading = false;
        showNotify({
          message: this.PAGELANG.inconformity,
          type: "warning",
        });
        return;
      }

      let postobj = new Object();

      postobj.invitecode = this.invitecode;
      postobj.agentname = this.agentname;
      postobj.password = this.password;
      postobj.username = this.username;
      postobj.email = this.email;

      console.log(postobj);

      this.axios.post(this.actions.doagentregist, postobj).then((response) => {
        //注册返回的数据
        console.log(response);

        this.isloading = false;

        let showinfo = this.PAGELANG[response.data.retinfo];

        if (!showinfo) {
          showinfo = response.data.retinfo;
        }

        closeToast();

        if (response.data.retcode == "OK") {
          //成功
          setCookie("agentloginid", response.data.agentid, 30);
          setCookie("agentlogintoken", response.data.logintoken, 30);

          // showSuccessToast({message: showinfo, onClose: () => {
          //   this.$router.push('/appflow');
          // }});
          showNotify({
            message: showinfo,
            type: "success",
            onClose: () => {
              this.$router.push("/appflow");
            },
          });
        } else {
          // showFailToast(showinfo);
          showNotify({
            message: showinfo,
            type: "warning",
          });
        }
      });
    },
  },
  mounted() {
    showLoadingToast({
      message: this.SYSLANG.loading,
      duration: 0,
      forbidClick: true,
    });

    this.axios.get(this.actions.mobile_prefix).then((response) => {
      closeToast();
      this.mobileperfix = response.data;
      let langitem = this.mobileperfix.find((item) => {
        if (item.value == this.langcode) return true;
        return false;
      });

      if (langitem) {
        this.selectedlang = [langitem.value];
        this.curPerfix = langitem.text;
      }
    });
  },
  setup() {
    //const $axios = inject("$axios");
    //const $refs = inject("$refs");

    const _this = getCurrentInstance();
    const gp = _this.appContext.config.globalProperties;

    const PAGELANG = gp.LANG.pages.register;
    const SYSLANG = gp.LANG.system;

    const langcode = getCookie("lh_aog_langcode");

    const appContext = _this.appContext;
    const mobileperfix = ref([]);
    const curPerfix = ref("");
    const showPrefix = ref(false);
    const invitecode = ref("");
    const username = ref("");
    const password = ref("");
    const password2 = ref("");
    const agentname = ref("");
    const email = ref("");
    const isloading = ref(false);
    const rules = {
      username: [{ required: true, message: PAGELANG.username }],
      password: [{ required: true, message: PAGELANG.password }],
      password2: [{ required: true, message: PAGELANG.password2 }],
      agentrequired: [{ required: true, message: PAGELANG.agentrequired }],
    };
    const selectedlang = ref([""]);
    const reginfo = ref([""]);

    return {
      PAGELANG,
      SYSLANG,
      mobileperfix,
      curPerfix,
      invitecode,
      username,
      password,
      password2,
      agentname,
      email,
      showPrefix,
      isloading,
      rules,
      selectedlang,
      appContext,
      langcode,
      reginfo,
    };
  },
};
</script>