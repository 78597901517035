<template>
  <div class="footer-pc">
    <div class="left-c">
      <img :src="require('@/assets/images/logo_footer.png')" class="logo" />
      Copyright © 2023 AOG Rights Reserved
    </div>
    <div class="right-c">
      <b>{{ SYSLANG.舒适新主义 }}</b
      ><br />
      {{ SYSLANG.简约舒适 }} &nbsp; / &nbsp; {{ SYSLANG.活力轻盈 }} &nbsp; /
      &nbsp; {{ SYSLANG.探索自由 }}
    </div>
  </div>
</template>

<script>
import { getCurrentInstance } from "vue";
export default {
  name: "PCFooter",
  beforeCreate() {
    const root = getCurrentInstance().appContext.config.globalProperties;
    this.SYSLANG = root.LANG.system;
    console.log(root.LANG, root.LANGUAGES);
    this.root = root;
  },
};
</script>


<style scoped>
@media (max-width: 768px) {
  .footer-pc {
    display: none;
  }
}

@media (min-width: 769px) {
  .footer-pc {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    height: 164px;
    width: 100%;
    padding: 0 calc(100vw * 360 / 1920);
    box-sizing: border-box;
    color: #000;
  }

  .footer-pc .left-c {
    width: 30%;
    font-size: 12px;
  }
  .footer-pc .left-c .logo {
    display: block;
    margin-bottom: 26px;
  }

  .footer-pc .right-c {
    font-size: 14px;
    line-height: 30px;
    text-align: right;
  }
}
</style>