<template>
  <div class="main-menu-cp">
    <div class="header-top">
      ALL OF GIVING
      <span>
        <i
          class="link iconfont icon-cart-03"
          @click="toLink('/cart')"
          style="font-size: 16px"
        />
        <span v-if="!isLogin" class="btn-register">
          <a href="#/login">{{ SYSLANG.登录 }}</a>
          &nbsp;/&nbsp;
          <a href="#/register">{{ SYSLANG.注册 }}</a>
        </span>
        <span v-else class="btn-register">
          <a href="#/profile">{{ MENULANG.my }}</a>
          &nbsp;/&nbsp;
          <a @click="onLogout">{{ MYLANG.退出 }}</a>
        </span>
      </span>
    </div>
    <img
      :src="require('@/assets/images/logo.png')"
      class="logo"
      :style="!showMenu ? 'margin-bottom: 40px;' : ''"
    />
    <div v-if="showMenu" class="menu">
      <ul class="pc-menu">
        <li>
          <a @click="toLink('/')">{{ SYSLANG.首页 }}</a>
        </li>
        <li>
          <a @click="toLink('/product')" class="iconfont icon-caidan">{{
            MENULANG.product
          }}</a>
        </li>
      </ul>
      <div class="search-block">
        <el-input
          class="search-key"
          v-model="searchKey"
          :placeholder="GOODSLANG.search"
          @keydown="searchKeydown"
        />
        <a class="iconfont icon-search" @click="searchPdt"></a>
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance } from "vue";
import { showConfirmDialog } from "vant";
import { setCookie, getCookie } from "../util";
export default {
  name: "PCHeader",
  data() {
    return {
      isLogin: false,
      searchKey: "",
      showMenu: true,
    };
  },
  created() {
    const root = getCurrentInstance().appContext.config.globalProperties;
    this.SYSLANG = root.LANG.system;
    this.MENULANG = root.LANG.mainmenu;
    this.GOODSLANG = root.LANG.pages.goods;
    this.MYLANG = root.LANG.pages.my;
    this.root = root;

    if (getCookie("agentloginid")) {
      this.isLogin = true;
    }

    let pagenames = ["Login", "register"];
    if (pagenames.indexOf(this.$route.name) > -1) {
      this.showMenu = false;
    }

    if (this.$route.query.SearchKey) {
      this.searchKey = this.$route.query.SearchKey;
    }
  },
  methods: {
    toLink(link) {
      if (!getCookie("agentloginid")) {
        this.$router.replace({
          path: "/login",
        });
      } else {
        this.$router.push({
          path: link,
        });
      }
      return;
    },

    searchKeydown(e) {
      if (e.keyCode == 13) {
        this.searchPdt();
      }
    },

    searchPdt() {
      console.log(this.searchKey);
      if (getCookie("agentloginid") && this.searchKey) {
        this.$router.push({
          path: "/product",
          query: {
            SearchKey: this.searchKey,
          },
        });

        /*this.$router.push({
          name: "product",
          params: { SearchKey: this.searchKey },
        });*/
      } else {
        return false;
      }
    },

    onLogout() {
      showConfirmDialog({
        title: this.SYSLANG.dialogtitle,
        message: this.MYLANG.您确定退出登录吗,
        confirmButtonText: this.SYSLANG.queding,
        cancelButtonText: this.SYSLANG.quxiao,
      })
        .then(() => {
          //退出登录
          setCookie("agentloginid", "");
          setCookie("agentlogintoken", "");
          this.$router.push("/login");
        })
        .catch(() => {});
    },
  },
  watch: {
    "$route.query"(val) {
      //console.log("PCHeader.$route.query", val);
      if (this.searchKey != val.SearchKey) {
        this.searchKey = val.SearchKey;
      }
    },
  },
};
</script>


<style scoped>
@media (max-width: 768px) {
  .main-menu-cp {
    display: none;
  }
}

@media (min-width: 769px) {
  .main-menu-cp {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    height: 168px;
    width: 100%;
    padding: 0 calc(100vw * 360 / 1920);
    box-sizing: border-box;
  }
  .main-menu-cp .logo {
    width: 120px;
  }
  .main-menu {
    display: none;
  }

  .main-menu-cp a,
  .main-menu-cp .link {
    cursor: pointer;
    color: #000;
  }

  .main-menu-cp .header-top {
    display: flex;
    height: 40px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    color: #000;
  }

  .main-menu-cp .header-top .btn-register {
    margin-left: 10px;
  }

  .main-menu-cp .menu {
    width: 100%;
    display: flex;
    height: 44px;
    align-items: center;
    justify-content: space-between;
  }

  .main-menu-cp .menu .pc-menu {
    display: flex;
    height: 40px;
  }
  .main-menu-cp .menu .pc-menu li {
    display: inline-flex;
    align-items: center;
    padding-right: 30px;
    font-size: 16px;
  }
  .main-menu-cp .menu .pc-menu li .iconfont::before {
    margin-right: 6px;
  }

  .main-menu-cp .menu .search-block {
    display: inline-flex;
    width: 190px;
    align-items: center;
    height: 30px;
    border-bottom: 1px solid #000;
  }

  .main-menu-cp .menu .search-block .search-key {
    width: 0;
    flex-grow: 1;
    border: 0;
    font-size: 12px;
    color: #000;

    --el-input-bg-color: transparent;
    --el-input-border-color: transparent;
    --el-input-hover-border-color: transparent;
    --el-input-hover-border: transparent;
    --el-input-focus-border: transparent;
    --el-color-danger: transparent;
    --el-input-focus-border-color: transparent;
  }
}
</style>